<template>
	<div>
		<ApexChartComponent
			:chartType="chartTypeGiven"
			:chartOptions="chartOptionsGiven"
			:series="chartSeries"
		/>
	</div>
</template>

<script>
import ApexChartComponent from '@/_srcv2/pages/business-development/time-interval-statistics/components/ApexChartComponent.vue'
export default {
	name: 'ChartFrame',
	components: {
		ApexChartComponent,
	},
	props: {
		chartType: {
			type: String,
			required: true,
		},
		chartOptions: {
			type: Object,
			required: true,
		},
		series: {
			type: Object,
			default: () => {},
		},
	},
	setup(props) {
		return {
			chartOptionsGiven: props.chartOptions,
			chartTypeGiven: props.chartType,
			chartSeries: props.series,
		}
	},
}
</script>

<style lang="scss" scoped></style>
