/** @format */

import Vue from 'vue'
import vueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(vueCompositionApi)

const state = reactive({})
// todo Helper function to format dates as 'yyyy-mm-dd'
const formatDate = (date) => {
	const yyyy = date.getFullYear()
	const mm = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
	const dd = String(date.getDate()).padStart(2, '0')
	return `${yyyy}-${mm}-${dd}`
}
// ? Function to get the first day of the month
const getFirstDayOfMonth = (date) => {
	return new Date(date.getFullYear(), date.getMonth(), 1)
}
// ? Function to get the last day of the month
const getLastDayOfMonth = (date) => {
	return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}
// ? Function to calculate the date based on months ago
const computeDate = (monthsAgo, firstDay = true) => {
	const today = new Date()
	const targetMonth = new Date(
		today.getFullYear(),
		today.getMonth() - monthsAgo,
		1,
	)
	const targetDate = firstDay
		? getFirstDayOfMonth(targetMonth)
		: getLastDayOfMonth(targetMonth)
	return formatDate(targetDate)
}
// todo ------------------------------------------------------------------------------------

export default function useChartState() {
	return {
		...toRefs(state),
		computeDate,
	}
}
