<template>
	<div>
		<div class="example">
			<VueApexCharts
				width="1330"
				height="600"
				:type="chartTypeGiven"
				:options="chartOptionsGiven"
				:series="chartSeries"
			/>
		</div>
		<div
			style="
				padding-bottom: 30px;
				display: flex;
				justify-content: space-between;
			"
		>
			<div class="margin-top-30" style="margin-left: 50px">
				<b-button
					class="width-250"
					style="background-color: rgb(61, 196, 134); color: white"
					@click="chartTypeGiven = 'area'"
				>
					Area Chart
				</b-button>
			</div>
			<div class="margin-top-30">
				<b-button
					class="width-250"
					style="background-color: rgb(52, 136, 206); color: white"
					@click="chartTypeGiven = 'line'"
				>
					Line Chart
				</b-button>
			</div>
			<div class="margin-top-30" style="margin-right: 50px">
				<b-button
					class="width-250"
					style="background-color: rgb(109, 65, 161); color: white"
					@click="chartTypeGiven = 'bar'"
				>
					Bar Chart
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { ref } from '@vue/composition-api'

export default {
	name: 'ApexChartComponent',
	components: {
		VueApexCharts,
	},
	props: {
		chartType: {
			type: String,
			required: true,
		},
		chartOptions: {
			type: Object,
			required: true,
		},
		series: {
			type: Array,
			default: () => [],
		},
	},
	setup(props) {
		const chartTypeGiven = ref('')
		chartTypeGiven.value = props.chartType
		const chartOptionsGiven = props.chartOptions
		const chartSeries = props.series
		return {
			chartTypeGiven,
			chartOptionsGiven,
			chartSeries,
		}
	},
}
</script>
