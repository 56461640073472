<template>
	<LayoutWide>
		<sub-nav-bar page="Marketing" />
		<div>
			<HeroBox :title="title" :subtitle="subtitle" :color="color" />
		</div>
		<div style="margin-bottom: 60px">
			<div style="background-color: rgb(76, 92, 122)">
				<div class="table-header">{{ tableTitle }}</div>
				<div>
					<b-dropdown :triggers="['hover']" aria-role="list">
						<template #trigger>
							<b-button
								label=""
								type="is-danger"
								class="width-250"
								icon-right="menu-down"
								style="margin-left: 20px"
							>
								{{ buttonTag }}
							</b-button>
						</template>
						<b-dropdown-item aria-role="listitem" @click="callEveryWeek"
							>Call Every Week</b-dropdown-item
						>
						<b-dropdown-item aria-role="listitem" @click="callMonthly"
							>Call Monthly</b-dropdown-item
						>
						<b-dropdown-item aria-role="listitem" @click="visitMonthly"
							>Visit Monthly</b-dropdown-item
						>
						<b-dropdown-item aria-role="listitem" @click="customerCallsUs"
							>Customer Calls Us</b-dropdown-item
						>
						<b-dropdown-item aria-role="listitem" @click="getOthers"
							>Other Customers</b-dropdown-item
						>
						<!-- <b-dropdown-item aria-role="listitem" @click="getAll"
							>Get All</b-dropdown-item
						> -->
					</b-dropdown>
				</div>
				<div
					style="
						display: flex;
						justify-content: start;
						font-weight: 900;
						background-color: rgb(76, 92, 122);
						padding-left: 20px;
						padding-bottom: 20px;
					"
				></div>
			</div>
			<div v-if="showTable">
				<vue-good-table
					:columns="columns"
					:rows="tableData"
					@on-row-dblclick="onRowDoubleClick"
					@on-cell-click="onCellClick"
					styleClass="vgt-table striped bordered"
					theme="black-rhino"
					max-height="700px"
					:fixed-header="true"
					:search-options="{
						enabled: true,
					}"
					:sort-options="sortOptions"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: '5',
						position: 'bottom',
						perPageDropdown: [5, 10, 15, 20, 100],
						dropdownAllowAll: true,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field !== 'customer'">
							<p
								v-if="props.row[props.column.field] === 0"
								class="text-bold text-red text-align-center"
							>
								No business
							</p>
							<p v-else class="text-bold text-align-right">
								{{ numeral(props.row[props.column.field]).format('0,0') }}
							</p>
						</span>
						<span
							v-else-if="props.column.field === 'customer'"
							@mouseover="hover = true"
							@mouseleave="hover = false"
						>
							<b-tooltip label="Click to view the Chart" position="is-right">
								<p
									class="special text-bold text-align-left"
									style="text-decoration: underline; color: purple"
								>
									{{ props.row[props.column.field] }}
								</p>
							</b-tooltip>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
					<template slot="table-column" slot-scope="props">
						<span v-if="props.column.label !== 'Nothing'">
							<p class="text-bold text-align-center">
								{{ props.column.label }}
							</p>
						</span>
						<span v-else>
							{{ props.column.label }}
						</span>
					</template>
				</vue-good-table>
			</div>
			<div
				style="
					background-color: rgb(76, 92, 122);
					padding-bottom: 40px;
					display: flex;
					justify-content: space-between;
				"
			>
				<div class="margin-top-30" style="margin-left: 15px">
					<b-button
						class="margin-left-15 width-250"
						style="background-color: rgb(61, 196, 134); color: white"
						@click="getLastNineMonths()"
					>
						Last Nine Months
					</b-button>
					<b-button
						class="margin-left-15 width-250"
						style="background-color: rgb(52, 136, 206); color: white"
						@click="getLastSixMonths"
					>
						Last Six Months
					</b-button>
					<b-button
						class="margin-left-15 width-250"
						style="background-color: rgb(109, 65, 161); color: white"
						@click="getLastThreeMonths"
					>
						Last Three Months
					</b-button>
				</div>
				<div>
					<download-csv :data="tableData" :name="fileName">
						<div class="margin-top-30" style="margin-right: 15px">
							<b-button
								type="is-danger"
								outlined
								class="width-250 margin-right-15"
							>
								Download the List
							</b-button>
						</div>
					</download-csv>
				</div>
			</div>
			<div>
				<div v-if="isChartVisible">
					<VePopup :closePopup="onClosePopup">
						<template #header>
							<div>
								{{ formName }}
							</div>
						</template>
						<template #body>
							<ChartFrame
								:key="chartKey"
								:chartType="chartTypeGiven"
								:chartOptions="chartOptionsGiven"
								:series="chartSeries"
							/>
						</template>
						<template #footer>
							<div>
								{{ formName }}
							</div>
						</template>
					</VePopup>
				</div>
			</div>
		</div>
	</LayoutWide>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from '@vue/composition-api'
import SubNavBar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import GetTimeIntervalCustomersInvoicesStatisticsQuery from '@/_srcv2/pages/business-development/statistics-pages/_graphql/GetTimeIntervalCustomersInvoicesStatisticsQuery.graphql'
import { useQuery } from '@vue/apollo-composable'

import Store from '@/store'
import useChartState from '@/_srcv2/pages/business-development/_shared/components/chart/useChartState.js'
import numeral from 'numeral'

import VePopup from '@/_srcv2/components/_shared/popup/VePopup.vue'
import ChartFrame from '@/_srcv2/pages/business-development/_shared/components/chart/ChartFrame.vue'

export default defineComponent({
	name: 'GetTimeIntervalCustomersInvoicesStatistics',
	components: {
		SubNavBar,
		HeroBox,
		VePopup,
		ChartFrame,
	},
	setup() {
		const title = ref(
			'Time Interval Customers Purchase Statistics (All Customers)',
		)
		const subtitle = ref(
			'You can see all the customer purchases by time interval and contact type',
		)
		const color = ref('is-info')
		// ? -----------------------------------------------------------
		const hover = ref(false)
		const data = ref([])
		const months = ref([])
		const columns = ref([])
		const tableData = ref([])
		const tableTitle = ref('Time Interval Sales Statistics by Options')
		const chartTitle = ref('')
		const fileName = ref('time-interval-sales-statistics.csv')
		const formName = ref('Chart Preview')
		const chartData = ref([])
		const chartYaxis = ref([])
		const chartXaxis = ref([])
		const chartSeries = ref()
		const chartTypeGiven = ref('area')
		const chartOptionsGiven = ref({})
		const isChartVisible = ref(false)
		const chartKey = ref(0)
		const sortOptions = reactive({
			enabled: true,
			multipleColumns: true,
			initialSortBy: [{ field: 'customer', type: 'asc' }],
		})
		const onClosePopup = () => {
			isChartVisible.value = false
		}
		const showTable = ref(false)
		const refreshTable = () => {
			console.log('refreshTable is called')
			showTable.value = false
			setTimeout(() => {
				showTable.value = true
			}, 1000)
		}
		// todo get dates -------------------------------------------------
		// todo Helper function to format dates as 'yyyy-mm-dd'
		// ? Reactive references for your dates
		const { computeDate } = useChartState()
		const date12MonthsAgo = ref(computeDate(12, true))
		const date9MonthsAgo = ref(computeDate(9, true))
		const date6MonthsAgo = ref(computeDate(6, true))
		const date3MonthsAgo = ref(computeDate(3, true))
		const date1MonthAgo = ref(computeDate(1, false))

		// todo end of get dates ------------------------------------------
		const getBalance = (sum) => {
			return Math.round((sum.line_price_total_credit + sum.vat_credit) / 100)
		}
		const mapTheData = (array) => {
			return array.map((item) => {
				return {
					customer: `${item._customer.customer_id} ${item._customer.customer_title}`,
					invoiceNumber: item.invoice_number,
					date: item.invoice_date,
					balance: getBalance(item._goods_transactions_aggregate.aggregate.sum),
				}
			})
		}
		const mapData = (queryResult) => {
			data.value = mapTheData(queryResult)
		}
		// todo get table rows ------------------------------------
		const getRowsForTable = () => {
			// todo Prepare the months and customers
			const customerSet = new Set()
			const monthSet = new Set()
			data.value.forEach((item) => {
				customerSet.add(item.customer)
				const dateObj = new Date(item.date)
				const year = dateObj.getFullYear()
				// const year = dateObj.getYear() - 100
				const month = dateObj.getMonth() + 1
				const monthKey = `${year}-${String(month).padStart(2, '0')}`
				monthSet.add(monthKey)
			})
			// todo Sort months
			const sortedMonths = Array.from(monthSet).sort()
			months.value = sortedMonths
			// todo Initialize columns array
			columns.value = sortedMonths.map((month) => ({
				field: month,
				label: month,
				width: '100px',
				html: true,
			}))
			columns.value.unshift({
				field: 'customer',
				label: 'Customer',
				width: '150px',
			})
			// todo Initialize tableData as an array of objects
			tableData.value = []
			customerSet.forEach((customer) => {
				const customerRow = { customer }
				sortedMonths.forEach((month) => {
					customerRow[month] = 0
				})
				tableData.value.push(customerRow)
			})
			// todo Accumulate balances
			data.value.forEach((item) => {
				const dateObj = new Date(item.date)
				const year = dateObj.getFullYear()
				// const year = dateObj.getYear() - 100
				const month = dateObj.getMonth() + 1
				const monthKey = `${year}-${String(month).padStart(2, '0')}`
				// todo Find the customer row in tableData
				const customerRow = tableData.value.find(
					(row) => row.customer === item.customer,
				)
				if (customerRow) {
					customerRow[monthKey] += item.balance
				}
			})
		}
		// todo -----------------------------------------------------------
		// todo Fetch the data from the GraphQL API
		const queryVariables = reactive({
			company_id: Store.getters.getUserCurrentCompany,
			bd: date9MonthsAgo.value,
			ed: date1MonthAgo.value,
			list_group: 'Call Every Week',
		})
		// ? ----------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch, onResult } = useQuery(
			GetTimeIntervalCustomersInvoicesStatisticsQuery,
			() => queryVariables,
			options,
		)
		onResult((result) => {
			mapData(result.data.document_transactions)
			getRowsForTable()
			refreshTable()
		})
		const GetTableData = () => {
			console.log('GetTableData is fired')
			refetch()
		}

		// todo -----------------------------------------------------------
		const buttonTag = ref('')
		const callEveryWeek = () => {
			queryVariables.list_group = 'Call Every Week'
			buttonTag.value = 'Call Every Week'
			GetTableData()
		}
		const callMonthly = () => {
			queryVariables.list_group = 'Call Monthly'
			GetTableData()
			buttonTag.value = 'Call Monthly'
		}

		const visitMonthly = () => {
			queryVariables.list_group = 'Visit Monthly'
			GetTableData()
			buttonTag.value = 'Visit Monthly'
		}
		const customerCallsUs = () => {
			queryVariables.list_group = 'Customer Calls Us'
			GetTableData()
			buttonTag.value = 'Customer Calls Us'
		}
		const getOthers = () => {
			queryVariables.list_group = 'Other Customers'
			GetTableData()
			buttonTag.value = 'Other Customers'
		}
		// todo ------------------------------------------------------------
		const getLastNineMonths = () => {
			queryVariables.bd = date9MonthsAgo.value
			GetTableData()
		}
		const getLastSixMonths = () => {
			queryVariables.bd = date6MonthsAgo.value
			GetTableData()
		}
		const getLastThreeMonths = () => {
			queryVariables.bd = date3MonthsAgo.value
			GetTableData()
		}
		// ** ------------------------------------------------------------
		onMounted(() => {
			sortOptions.initialSortBy = [{ field: 'customer', type: 'asc' }]
			buttonTag.value = 'Call Every Week'
			GetTableData()
		})
		// todo -------------------------------------------------------------
		const getChartVariables = (row) => {
			return new Promise((resolve, reject) => {
				if (row) {
					// todo Your original chartData object
					chartTitle.value = Object.values(row)[0]
					// ? 1. Remove unwanted properties
					const cleanedObject = { ...row }
					delete cleanedObject.customer
					delete cleanedObject.originalIndex
					delete cleanedObject.vgt_id
					delete cleanedObject.vgtSelected
					console.log('cleanedObject', cleanedObject)
					chartXaxis.value = Object.keys(cleanedObject)
					// todo Step 3: Create an array of all values after removal
					chartYaxis.value = Object.values(cleanedObject)
					console.log('chartXaxis', chartXaxis.value)
					chartSeries.value = ref([
						{
							name: 'Total Purchases',
							data: chartYaxis.value,
						},
					])
					console.log('chartSeries', chartSeries.value)
					chartOptionsGiven.value = ref({
						xaxis: {
							categories: chartXaxis.value,
						},
						title: {
							text: chartTitle.value,
							align: 'left',
						},
						subtitle: {
							text: tableTitle.value,
							align: 'left',
						},
						stroke: {
							curve: 'smooth',
						},
					})
					console.log('chartOptionsGiven', chartOptionsGiven.value)
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const onCellClick = (params) => {
			if (params.column.field === 'customer') {
				console.log(params.row)
				getChartVariables(params.row).then(() => {
					isChartVisible.value = true
				})
			}
		}
		const onRowDoubleClick = (params) => {
			getChartVariables(params.row).then(() => {
				isChartVisible.value = true
			})
		}
		return {
			hover,
			title,
			subtitle,
			color,
			showTable,
			fileName,
			formName,
			columns,
			isChartVisible,
			onClosePopup,
			tableData,
			chartData,
			chartXaxis,
			chartYaxis,
			chartSeries,
			chartTypeGiven,
			chartOptionsGiven,
			chartTitle,
			chartKey,
			sortOptions,
			// -----------------------------------------------------------------
			date12MonthsAgo,
			date9MonthsAgo,
			date6MonthsAgo,
			date3MonthsAgo,
			date1MonthAgo,
			tableTitle,
			numeral,
			// -----------------------------------------------------------------
			buttonTag,
			callEveryWeek,
			callMonthly,
			visitMonthly,
			customerCallsUs,
			getOthers,
			getLastNineMonths,
			getLastSixMonths,
			getLastThreeMonths,
			// -----------------------------------------------------------------
			onCellClick,
			onRowDoubleClick,
		}
	},
})
</script>

<style scoped>
/* Add any custom styles here */
.table-number {
	text-align: right;
	color: red;
}
.special {
	cursor: pointer;
}
</style>
